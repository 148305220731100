defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/AppList',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Utils'
  ],
  function (Component, Utils) {
    Component('app-list', function () {
      var maxHeight = 0;
      var $listItems = $DS('.app-list__item');
      $listItems.each(function () {
        var itemHeight = $DS(this).outerHeight();
        maxHeight = itemHeight > maxHeight ? itemHeight : maxHeight;
      });
      $listItems.each(function () {
        $DS(this).css('height', maxHeight);
      });

      Utils.logDeprecated('AppList.js loaded'); // Added by ANVP 2024-04-19
    });
  });
